<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Kursangebot erstellen</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0">
                                <label for="account-add-course-category" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Kursart</label>
                                <select class="form-select mb-3" id="account-add-course-category" aria-label="Kursart" v-model="$v.typeId.$model">
                                    <option selected :value="undefined">Kursart wählen</option>
                                    <option :value="c.id" v-for="c in _courseTypes" :key="`${keyPrefix}_${c.id}`">{{ c.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 px-0">
                                <input type="text" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="course-title" placeholder="Kurstitel" v-model="$v.title.$model" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="$v.$invalid || isLoading" @click="doCreate">
                                    <span v-if="isLoading" class="spinner-grow-sm text-white" role="status">Wird erstellt...</span>
                                    <span v-else>Erstellen</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import BaseModal from "./BaseModal";
    import { required } from "vuelidate/lib/validators";
    import { mixinBasics } from "../../mixins/basics";


    export default {
        name: "CourseCreate",

        extends: BaseModal,

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'courseCreate',
                modalId: 'course-create-modal',
                modal: undefined,
                isLoading: false,

                typeId: undefined,
                title: undefined,
            }
        },

        validations: {
            typeId: { required },
            title: { required}
        },

        methods: {
            async doCreate() {
                this.$emit('create', {typeId: this.typeId, title: this.title});
            },
        }
    }
</script>



<style scoped>

</style>
