<template>
    <div class="container px-0" v-if="!isLoading">
        <!-- video link -->
        <div class="row">
            <div class="col-12">
                <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Kurzvideo</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                    <label for="account-add-course-video1"
                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Link
                        zu Youtube, Vimeo oder von Ihrer Website</label>
                    <input type="text" class="form-control mb-3" id="account-add-course-video1" placeholder="https://">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                    <input
                        class="align-self-center form-check-input account-about-me-field-checkbox mt-0 ms-2 me-2"
                        type="checkbox"
                        :checked="!!course.publish"
                        id="account-about-me-publish"
                        @input="togglePublish($event.target.checked)"
                    />
                    <label
                        class="align-self-center form-check-label account-about-me-field-label mp-qks font-size-account-about-me-field mp-font-weight-medium"
                        for="account-about-me-publish">
                        Kurs veröffentlichen
                    </label>
                </div>
            </div>
        </div>

        <!-- photos -->
        <div class="row mt-3">
            <div class="col-12">
                <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Fotos</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                    <div class="row">
                        <!-- images -->
                        <div class="col-6 mb-4 col-md-4 mb-md-4 col-lg-2 mb-lg-0 position-relative" v-for="(img, idx) in images" :key="`${keyPrefix}_${idx}_i`">
                            <template v-if="img">
                                <img class="uploaded-img" :src="img.url" :alt="`Bild ${idx+1}`" />
                                <div class="img-delete">
                                    <i class="fas fa-trash d-block" style="cursor: pointer" @click="showDialogImageDelete(img.id)"></i>
                                </div>
                            </template>
                            <FileUpload v-else :url="mediaUploadUrl" @success="refreshCourseMedia" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- course desc. -->
        <div class="row mt-3">
            <div class="col-12 col-lg-6">
                <div class="container px-0">
                    <div class="row">
                        <div class="col-12">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Kursbeschreibung</span>
                        </div>
                    </div>
                    <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                        <div class="row">
                            <div class="col-12">
                                <label for="account-add-course-category" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Kategorie</label>
                                <select class="form-select mb-3" id="account-add-course-category" aria-label="Kategorie" v-model="course.category_id">
                                    <option selected :value="undefined">Kategorie wählen</option>
                                    <option :value="c.id" v-for="c in categories" :key="`${keyPrefix}_ccat_${c.id}`">{{ c.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="account-add-course-field" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Fachgebiet</label>
                                <select class="form-select mb-3" id="account-add-course-field" aria-label="Fachgebiet" v-model="course.area_id">
                                    <option selected :value="undefined">Fachgebiet wählen</option>
                                    <option :value="a.id" v-for="a in areas" :key="`${keyPrefix}_carea_${a.id}`">{{ a.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="account-add-course-type" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Art des Angebotes</label>
                                <select class="form-select mb-3" id="account-add-course-type" aria-label="Art des Angebotes" v-model="course.course_type_id">
                                    <option selected>Art des Angebotes wählen</option>
                                    <option :value="t.id" v-for="t in _courseTypes" :key="`${keyPrefix}_ctype_${t.id}`">{{ t.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" v-if="course.course_type_id && _courseTypes.filter(e => e.id === course.course_type_id && e.meta === '1').length > 0">
                            <div class="col-12">
                                <label for="account-add-course-title"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Art des Angebotes / Zusatzinformation</label>
                                <input type="text" class="form-control mb-3" id="account-add-course-title" v-model="course.course_type_desc">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="account-add-course-title"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Titel</label>
                                <input type="text" class="form-control mb-3" id="account-add-course-title" v-model="course.title">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex flex-column mb-3">
                                <label for="account-add-course-short-description"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Kurzbeschreibung</label>
                                <input type="text" class="form-control" id="account-add-course-short-description" v-model="course.desc_brief">
                                <span class="align-self-end mp-rbk font-size-account-form-addon">max. 80 Zeichen</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-10 d-flex flex-column">
                                <label for="account-add-course-description" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">
                                    Langbeschreibung
                                </label>

                            </div>

                            <div class="col-2 text-end">
                                <!-- open description button -->
                                <button type="button" class="btn course-info justify-content-center text-center form-shadow" data-bs-toggle="modal" data-bs-target="#course-description-modal">
                                    <i class="fas fa-info"></i>
                                </button>
                                <!-- /open description button -->

                                <!-- description modal -->
                                <div class="modal fade" id="course-description-modal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="course-description-modal-title" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg">
                                        <div class="modal-content p-4">
                                            <div class="modal-header border-0 p-0 d-flex justify-content-center">
                                                <span class="modal-title expert-detail-fields-of-experience-description-header mp-rbk mp-font-weight-semi-bold" id="course-description-modal-title">
                                                    Begriffserklärung
                                                </span>
                                            </div>
                                            <div class="modal-body py-4">
                                                <p class="field-of-expertise-description mp-qks font-size-field-of-expertise-description text-start">
                                                    Beschreibe dein Angebot kurz, aber dennoch genau.<br>
                                                    Bleibe deinem persönlichen Stil treu, damit sich der potenzielle Kunde einen ersten Eindruck davon machen kann.<br>
                                                    Achte darauf, dass du passende Fachbegriffe verwendest, um professionell zu wirken, aber den Kunden nicht überforderst.<br>
                                                    Füge ein Kurzvideo und Bilder von deinem Angebot hinzu, um die visuelle Komponente zu verstärken.
                                                </p>
                                            </div>
                                            <div class="modal-footer d-flex flex-column justify-content-center border-0 p-0">
                                                <button type="button" class="btn btn-mp-green font-size-btn-close-field-of-expertise-description mp-font-weight-medium py-2 px-5" data-bs-dismiss="modal">Schließen</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /description modal -->
                            </div>

                            <div class="col-12 d-flex flex-column" style="padding-top: 5px">
                                <textarea class="form-control" id="account-add-course-description" rows="5" v-model="course.desc"></textarea>
                                <span class="align-self-end mp-rbk font-size-account-form-addon">max. 500 Zeichen</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Weitere Details</span>
                        </div>
                    </div>
                    <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                        <div class="row mb-3">
                            <div class="col-12 d-flex">
                                <span class="mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular me-4">Art der Veranstaltung:</span>
                                <span class="form-check-label" style="margin-right: 10px">Live-Event</span>
                                <div class="form-check form-switch">
                                    <label class="form-check-label" for="account-add-course-is-online">Online-Event</label>
                                    <input class="form-check-input toggle-switch" type="checkbox" id="account-add-course-is-online" v-model="course.is_online_only">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12 col-lg-6">
                                <label for="account-add-course-date"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Startdatum</label>
                                <input id="account-add-course-date" class="form-control" type="date" v-model="course.date_start"/>
                            </div>
                            <div class="col-12 col-lg-6">
                                <label for="account-add-course-date"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Enddatum</label>
                                <input id="account-add-course-date" class="form-control" type="date" v-model="course.date_end"/>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row">
                                    <div class="col-4">
                                        <label for="account-add-course-time-from"
                                               class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Startzeit</label>
                                        <input id="account-add-course-time-from" class="form-control" type="time" v-model="course.time_from"/>
                                    </div>
                                    <div class="col-4">
                                        <label for="account-add-course-time-to"
                                               class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Endzeit</label>
                                        <input id="account-add-course-time-to" class="form-control" type="time" v-model="course.time_to"/>
                                    </div>
                                    <div class="col-4">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <label for="account-add-course-price"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Preis in €</label>
                                <input type="text" class="form-control" id="account-add-course-price"
                                       placeholder="0" v-model="course.price">
                            </div>
                        </div>

                        <template v-if="course.is_online_only">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label for="account-add-course-street"
                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Zusatzinformationen</label>
                                    <textarea class="form-control" id="account-add-course-description" rows="5" v-model="course.additional_desc"></textarea>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label for="account-add-course-street"
                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Kursort</label>
                                    <input type="text" class="form-control" id="account-add-course-street"
                                           placeholder="Adresse" v-model="course.address">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-4 col-md-3">
                                    <label for="account-add-course-zip"
                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">PLZ</label>
                                    <input type="text" class="form-control" id="account-add-course-zip" v-model="course.zip">
                                </div>
                                <div class="col-8 col-md-9">
                                    <label for="account-add-course-city"
                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ort</label>
                                    <input type="text" class="form-control" id="account-add-course-city" v-model="course.city">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label for="account-add-course-country"
                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Land</label>
                                    <select class="form-select" id="account-add-course-country" aria-label="Land" v-model="course.country">
                                        <option selected :value="undefined">Auswählen</option>
                                        <option value="AT">Österreich</option>
                                        <option value="DE">Deutschland</option>
                                        <option value="CH">Schweiz</option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <div class="row">
                            <div class="col-12">
                                <label for="account-add-course-url" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">URL zum Angebot</label>
                                <input type="text" class="form-control" id="account-add-course-url" placeholder="https://ihre.kursseite.at" v-model="course.web_url">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="container px-0">
                    <div class="row">
                        <div class="col-12">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Ziele deines Angebotes</span>
                        </div>
                    </div>
                    <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-goal1"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ziel
                                    1</label>
                                <input type="text" class="form-control" id="account-add-course-goal1" v-model="course.objective_1">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-goal2"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ziel
                                    2</label>
                                <input type="text" class="form-control" id="account-add-course-goal2" v-model="course.objective_2">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-goal3"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ziel
                                    3</label>
                                <input type="text" class="form-control" id="account-add-course-goal3" v-model="course.objective_3">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-goal4"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ziel
                                    4</label>
                                <input type="text" class="form-control" id="account-add-course-goal4" v-model="course.objective_4">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-goal5"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ziel
                                    5</label>
                                <input type="text" class="form-control" id="account-add-course-goal5" v-model="course.objective_5">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="account-add-course-goal6"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ziel
                                    6</label>
                                <input type="text" class="form-control" id="account-add-course-goal6" v-model="course.objective_6">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Im Angebot enthalten</span>
                        </div>
                    </div>
                    <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-units"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Anzahl
                                    der Vorträge, Lerneinheiten, Workshops, ...</label>
                                <input type="text" class="form-control" id="account-add-course-units"
                                       placeholder="z.B. 1 Vortrag oder 1 Seminar á 5 Lerneinheiten" v-model="course.desc_units">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-duration"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Laufzeit</label>
                                <input type="text" class="form-control" id="account-add-course-duration"
                                       placeholder="z.B. Dauer 8h oder 8h pro Lerneinheit (Gesamt 40h)" v-model="course.desc_duration">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <label for="account-add-course-learning-materials"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Enthaltene
                                    Lernmaterialien</label>
                                <input type="text" class="form-control" id="account-add-course-learning-materials"
                                       placeholder="z.B. Skript (PDF), …" v-model="course.desc_learning_material">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Such-Tags</span>
                        </div>
                    </div>
                    <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">


                        <div class="row mb-3">
                            <!-- TODO: -->
                            <!--
                            <div class="col-12">
                                <label for="account-add-course-search-tags"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Experte
                                    in</label>
                                <select class="form-select" id="account-add-course-search-tags" aria-label="Such-Tags">
                                    <option selected>Fachgebiet wählen</option>
                                    <option value="1">Fachgebiet 1</option>
                                    <option value="2">Fachgebiet 2</option>
                                    <option value="3">Fachgebiet 3</option>
                                </select>
                            </div>
                            -->
                            <label for="course-search-tags"
                                   class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Experte in (Mehrfachauswahl möglich)
                            </label>
                            <Select2
                                id="course-search-tags"
                                aria-label="Such-Tags"
                                v-model="tags"
                                :options="optionsTags"
                                :settings="{ multiple: true }"
                                style="width:100%"
                            />
                        </div>
                        <!--
                        <div class="row">
                            <div class="col-12 d-flex">
                                <a class="mp-green mp-qks mp-font-weight-medium font-size-account-expert-about-me-search-tag-add text-decoration-none d-flex"
                                   href="#"><i
                                    class="fas fa-plus-circle me-2 font-size-account-expert-about-me-search-tag-add-icon"></i>
                                    Weiteres Fachgebiet hinzufügen</a>
                            </div>
                        </div>
                        -->
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3 mb-md-0 d-flex flex-column">
                            <button type="button" class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5 bg-primary" @click="doCancel">
                                Zurück
                            </button>
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column">
                            <button type="button" class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5" @click="doSave">
                                Speichern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- dialogs -->
        <Confirm v-if="showDlgImageDelete" title="Bild löschen" :msg="`Bild wirklich löschen?`" @positive="doImageDelete" @close="showDlgImageDelete = false"/>

    </div>
</template>




<script>
    import {mixinBasics} from "../mixins/basics";
    import Configs from '../configs';
    import {apiCall, buildFinalUrl} from '../helpers';
    import FileUpload from "./FileUpload";
    import Confirm from "./modals/Confirm";
    import Select2 from 'v-select2-component';

    const api = Configs.restApi.endpoints.map;


    export default {
        name: "Course",

        components: {
            Confirm,
            FileUpload,
            Select2
        },

        mixins: [
            mixinBasics
        ],

        props: {
            id: {
                required: true,
            },
            expertProfile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                keyPrefix: 'course',
                isLoading: true,
                course: undefined,
                tags: [],

                selectedImageId: undefined,
                showDlgImageDelete: undefined
            };
        },

        computed: {
            categories() {
                // return only categories covered by the expert
                return this.expertProfile.categories;
            },

            selectedCategory() {
                return this.course.category_id ? this.categories.find(c => c.id === this.course.category_id ) : undefined;
            },

            areas() {
                // return only areas covered by the expert
                let areas = [];
                if (this.selectedCategory) {
                    let categoryAreas = this._categories.find(c => c.id === this.selectedCategory.id).areas;
                    areas = this.expertProfile.areas.filter(ea => categoryAreas.find(ca => ca.id === ea.id && ea.verified));
                }
                return areas;
            },

            images() {
                let img = [];
                for (let i = 0; i <= 5; i++) {
                    let m = this.course.media[i];
                    img.push(m ? m : undefined)
                }
                return img;
            },

            mediaUploadUrl() {
                return buildFinalUrl(api.course.media.create.url, {id: this.id});
            },

            optionsTags()
            {
                return this._tags.map(e => {
                        return {text: e.title, id: e.id};
                    }
                );
            },
        },

        methods: {
            async getData() {
                let req = api.course.show;
                let res = await apiCall(buildFinalUrl(req.url, {id: this.id}), req.method);
                this.course = res.data;
                this.tags = this.course.tags.map(e => e.id);
            },

            doCancel() {
                this.$emit('cancel');
            },

            doSave() {
                this.$emit('save', Object.assign(this.course, {tags: this.tags}));
            },

            showDialogImageDelete(imgId) {
                this.selectedImageId = imgId;
                this.showDlgImageDelete = true;
            },

            async doImageDelete() {
                let imgId = this.selectedImageId;
                let req = api.course.media.delete;
                await apiCall(buildFinalUrl(req.url, {id: this.id, mediaId: imgId}), req.method);
                await this.refreshCourseMedia();
            },

            async refreshCourseMedia() {
                let req = api.course.media.index;
                let res = await apiCall(buildFinalUrl(req.url, {id: this.id}), req.method);
                this.course.media = res.data;
            },

            togglePublish(val) {
                // just converts from true/false --> 1/0
                this.course.publish = val ? 1 : 0;
            }
        },

        async created() {
            this.isLoading = true;
            await this.getData();
            this.isLoading = false;
        }
    }
</script>



<style scoped>
    .uploaded-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .img-delete {
        position: absolute;
        bottom: 0.35rem;
        right: 1.35rem;
        color: #FC3C53;
        background-color: #fff;
        padding: 0.45rem;
        border-radius: 1rem;
    }

    .course-info {
        /* position: absolute; */
        top: 0.55rem;
        right: 0.55rem;
        width: 2.0rem;
        height: 2.0rem;
        background-color: #7FAA2D;
        color: #fff;
        border-radius: 1rem;
        font-size: 0.8rem;
    }

    .course-info:hover {
        color: #fff;
    }
</style>