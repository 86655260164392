<template>
    <div class="text-center d-flex flex-column w-100 h-100 justify-content-center grey-border form-shadow-light">
        <input class="d-none" ref="uploader" type="file" :accept="allowedFileTypes" @change="doUpload">

        <button :disabled="isLoading"
                type="button"
                class="d-block align-self-center bg-transparent border-0 mp-green mp-rbk mp-font-weight-semi-bold font-size-account-upload-image-link"
                @click="$refs.uploader.click()">

            <template v-if="isLoading">
                <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                <span>HOCHLADEN...</span>
            </template>
            <span v-else class="text-decoration-underline">BILD HOCHLADEN</span>
        </button>
    </div>
</template>



<script>
    import Const from "../constants";
    import {apiCall} from "../helpers";

    const allowedFileTypes = '.gif,.jpg,.png,.jpeg,.pdf';


    export default {
        name: "FileUpload",

        components: {
        },

        data() {
            return {
                allowedFileTypes,
                isLoading: false,
            };
        },

        props: {
            url: {
                type: String,
                required: true
            },
        },

        methods: {
            async doUpload() {
                this.isLoading = true;
                let file = this.$refs.uploader.files[0];
                let formData = new FormData();
                formData.append('file', file);
                let res = await apiCall(this.url, Const.restApi.METHOD_POST, formData);
                this.isLoading = false;

                if (res.error === 0) {
                    this.$emit('success');
                }
            },
        },
    }
</script>



<style scoped>

</style>
