<template>

    <!-- content -->
    <div class="container-fluid bg-lightblue" v-if="!isLoading">
        <div class="row">
            <div class="col-12">
                <div class="container py-5">
                    <div class="row mb-4">
                        <div class="col-12">
                            <span
                                class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-account-title pt-3 mb-3">Experten-Konto</span>
                        </div>
                    </div>

                    <!-- Tabs navs ------------------------------------------------------------------------------------------------------------->
                    <div class="row">
                        <div class="col-12">
                            <ul class="nav nav-tabs flex-column flex-lg-row mb-3" id="acc" role="tablist">
                                <!--
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.account ? 'active' : ''"
                                        id="my-account-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#my-account"
                                        type="button" role="tab"
                                        aria-controls="my-account"
                                        aria-selected="true"
                                        @click="activeTab = tabPages.account">
                                        MEIN ACCOUNT
                                    </button>
                                </li>
                                -->
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.about ? 'active' : ''"
                                        id="about-me-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#about-me"
                                        type="button" role="tab"
                                        aria-controls="about-me"
                                        aria-selected="false"
                                        @click="activeTab = tabPages.about">
                                        PROFIL BEARBEITEN
                                    </button>
                                </li>
                                <li v-if="subscribedProduct && subscribedProduct.level > 1" class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.offers ? 'active' : ''"
                                        id="my-offers-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#my-offers"
                                        type="button" role="tab"
                                        aria-controls="my-offers"
                                        aria-selected="false"
                                        @click="showCourseDetail = false; activeTab = tabPages.offers">
                                        ANGEBOTE VERWALTEN
                                    </button>
                                </li>
                                <!--
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.favExperts ? 'active' : ''"
                                        id="favorites-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#favorites"
                                        type="button" role="tab"
                                        aria-controls="favorites"
                                        aria-selected="false"
                                        @click="activeTab = tabPages.favExperts">
                                        GEMERKTE EXPERTEN
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.favCourses ? 'active' : ''"
                                        id="favorite-courses-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#favorite-courses"
                                        type="button" role="tab"
                                        aria-controls="favorite-courses"
                                        aria-selected="false"
                                        @click="activeTab = tabPages.favCourses">
                                        GEMERKTE KURSE
                                    </button>
                                </li>
                                -->
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.reviews ? 'active' : ''"
                                        id="reviews-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#reviews"
                                        type="button" role="tab"
                                        aria-controls="reviews"
                                        aria-selected="false"
                                        @click="activeTab = tabPages.reviews">
                                        ERFAHRUNGSBERICHTE EINSEHEN
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.subscription ? 'active' : ''"
                                        id="subscription-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#subscription"
                                        type="button" role="tab"
                                        aria-controls="subscription"
                                        aria-selected="false"
                                        @click="activeTab = tabPages.subscription">
                                        PAKETE AKTUALISIEREN
                                    </button>
                                </li>
                                <!--
                                <li class="nav-item" role="presentation">
                                    <button
                                        class="nav-link account-tab-title mp-rbk py-2 pe-3 ps-0 font-size-account-tab-title"
                                        :class="activeTab === tabPages.settings ? 'active' : ''"
                                        id="settings-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#settings"
                                        type="button" role="tab"
                                        aria-controls="settings"
                                        aria-selected="false"
                                        @click="activeTab = tabPages.settings">
                                        EINSTELLUNGEN
                                    </button>
                                </li>
                                -->
                            </ul>
                            <!-- /Tabs navs -->


                            <!-- my-account ----------------------------------------------------------------------------------------------------------------------->
                            <div class="tab-content pt-4" id="acc-content">
                                <div class="tab-pane fade" :class="activeTab === tabPages.account ? 'show active' : ''"
                                     id="my-account" role="tabpanel" aria-labelledby="my-account-tab">
                                    <div class="container px-0">
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <span
                                                    class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Persönliche Daten</span>
                                                <div
                                                    class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                                    <div class="container px-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-salutation"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Anrede
                                                                    *</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-salutation"
                                                                       v-model="userProfile.salutation">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-title"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Titel</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-title"
                                                                       v-model="userProfile.title">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-firstname"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Vorname
                                                                    *</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-firstname"
                                                                       v-model="userProfile.firstname">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-lastname"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Nachname
                                                                    *</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-lastname"
                                                                       v-model="userProfile.lastname">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="container my-4 px-0">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-6 d-flex flex-column">
                                                            <button type="button" class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5" @click="doUserProfileSave">
                                                                Speichern
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <span
                                                    class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Zugangsdaten</span>
                                                <div
                                                    class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4">
                                                    <div class="container px-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-email"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">E-Mail
                                                                    *</label>
                                                                <input type="email" class="form-control mb-3"
                                                                       id="account-expert-email"
                                                                       v-model="userProfile.email" disabled readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="container mt-4 px-0">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-6 d-flex flex-column">
                                                            <button type="button" class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5 mb-4" @click="showDlgEMailChange = true">
                                                                E-Mail ändern
                                                            </button>
                                                        </div>
                                                        <div class="col-12 col-lg-6 d-flex flex-column">
                                                            <button type="button" class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5 mb-4" @click="showDlgPasswordChange = true">
                                                                Kennwort ändern
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /my-account-tab content -->
                                </div>


                                <!-- about-me -------------------------------------------------------------------------------------------------------------------->
                                <div class="tab-pane fade" :class="activeTab === tabPages.about ? 'show active' : ''"
                                     id="about-me" role="tabpanel" aria-labelledby="about-me-tab">
                                    <div class="container px-0">
                                        <div class="row">
                                            <div class="col-12 col-lg-8 mb-4">
                                                <p style="font-weight: bold; border: 2px solid #f00; border-radius: 5px; color: #dc3545; padding: 9px; text-align: center;" v-if="!profile.subscription">
                                                    Achtung! Du hast noch kein Paket gewählt, somit ist dein Profil noch nicht veröffentlicht.
                                                </p>
                                            </div>

                                            <div class="col-12 col-md-4 mb-4">
                                                <a ref="expertPreview" :href="`/experten/${expertNameMeta}/${expertProfile.id}/detail`" target="_blank" style="visibility: hidden" />
                                                <button
                                                    type="button"
                                                    class="btn btn-mp-blue font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5 w-100"
                                                    @click="doExpertPreview"
                                                >
                                                    Vorschau
                                                </button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12">
                                                <span
                                                    class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Kurzvideos</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <div
                                                    class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                    <label for="account-expert-video1"
                                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Link
                                                        zu Youtube, Vimeo oder von deiner Website</label>
                                                    <input
                                                        type="text"
                                                        class="form-control mb-3"
                                                        id="account-expert-video1"
                                                        placeholder="https://"
                                                        v-model="expertProfile.video_1_url"
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <div
                                                    class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                    <label for="account-expert-video2"
                                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Link
                                                        zu Youtube, Vimeo oder von deiner Website</label>
                                                    <input
                                                        type="text"
                                                        class="form-control mb-3"
                                                        id="account-expert-video2"
                                                        placeholder="https://"
                                                        v-model="expertProfile.video_2_url"
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <span
                                                    class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Profilfotos</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div
                                                    class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                    <div class="row">
                                                        <!-- images -->
                                                        <div class="col-6 mb-4 col-md-4 mb-md-4 col-lg-2 mb-lg-0 position-relative" v-for="(img, idx) in images" :key="`${keyPrefix}_${idx}_i`">
                                                            <template v-if="img">
                                                                <img class="uploaded-img" :src="img.url" alt="Bild 1" />
                                                                <div class="img-delete">
                                                                    <i class="fas fa-trash d-block" style="cursor: pointer" @click="showDialogExportProfileImageDelete(img.id)"></i>
                                                                </div>
                                                            </template>
                                                            <FileUpload v-else :url="expertMediaUploadUrl" @success="getExpertProfile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 col-lg-6">
                                                <div class="container px-0">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <span
                                                                class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Daten zur Person</span>
                                                        </div>
                                                    </div>
                                                    <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label
                                                                    for="account-expert-about-me-salutation"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    :class="$v.expertProfile.salutation.$error ? 'error-msg' : ''"
                                                                >Anrede *</label>
                                                                <select
                                                                    class="form-select"
                                                                    :class="$v.expertProfile.salutation.$error ? 'error-border' : ''"
                                                                    id="account-expert-about-me-salutation"
                                                                    aria-label="Anrede"
                                                                    v-model="$v.expertProfile.salutation.$model"
                                                                >
                                                                    <option value="Herr">Herr</option>
                                                                    <option value="Frau">Frau</option>
                                                                    <option value="Keine Angabe">Keine Angabe</option>
                                                                </select>
                                                                <div v-if="$v.expertProfile.salutation.$error" class="error-msg">Bitte Anrede auswählen</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mt-3">
                                                            <!-- title: leading-->
                                                            <div class="col-12 col-lg-6">
                                                                <label
                                                                    for="registration-expert-salutation"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                >Titel (vorangestellt)</label>
                                                                <select
                                                                    class="form-select"
                                                                    id="registration-expert-salutation"
                                                                    aria-label="Land"
                                                                    v-model="expertProfile.title"
                                                                >
                                                                    <option value="">[Auswählen]</option>
                                                                    <option v-for="(t, idx) in academTitles.leading" :value="t" :key="`tl_${idx}`">{{t}}</option>
                                                                </select>
                                                            </div>
                                                            <!-- title: trailing -->
                                                            <div class="col-12 col-lg-6">
                                                                <label
                                                                    for="registration-expert-salutation"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                >Titel (nachgestellt)</label>
                                                                <select
                                                                    class="form-select"
                                                                    id="registration-expert-salutation"
                                                                    aria-label="Land"
                                                                    v-model="expertProfile.title_trailing"
                                                                >
                                                                    <option value="">[Auswählen]</option>
                                                                    <option v-for="(t, idx) in academTitles.trailing" :value="t" :key="`tt_${idx}`">{{t}}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="row mt-3">
                                                            <div class="col-12">
                                                                <label
                                                                    for="account-expert-about-me-firstname"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    :class="$v.expertProfile.firstname.$error ? 'error-msg' : ''"
                                                                >Vorname *</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    :class="$v.expertProfile.firstname.$error ? 'error-border' : 'mb-3'"
                                                                    id="account-expert-about-me-firstname"
                                                                    v-model="$v.expertProfile.firstname.$model"
                                                                >
                                                                <div v-if="$v.expertProfile.firstname.$error" class="error-msg">Bitte Vorname angeben</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label
                                                                    for="account-expert-about-me-lastname"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    :class="$v.expertProfile.lastname.$error ? 'error-msg' : ''"
                                                                >Nachname *</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    :class="$v.expertProfile.lastname.$error ? 'error-border' : 'mb-3'"
                                                                    id="account-expert-about-me-lastname"
                                                                    v-model="$v.expertProfile.lastname.$model"
                                                                >
                                                                <div v-if="$v.expertProfile.lastname.$error" class="error-msg">Bitte Nachname angeben</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12 d-flex flex-column mb-3">
                                                                <label for="account-expert-about-me-short-description"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Kurzbeschreibung</label>
                                                                <input type="text" class="form-control"
                                                                       id="account-expert-about-me-short-description"
                                                                       maxlength="85"
                                                                       v-model="$v.expertProfile.desc_brief.$model"
                                                                >
                                                                <div v-if="$v.expertProfile.desc_brief.$error" class="align-self-end mp-rbk font-size-account-form-addon error-msg">max. 80 Zeichen</div>
                                                                <span v-else class="align-self-end mp-rbk font-size-account-form-addon">max. 80 Zeichen</span>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-10 d-flex flex-column">
                                                                <label for="account-expert-about-me-description" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">
                                                                    Beschreibe deine Dienstleistung
                                                                </label>
                                                            </div>

                                                            <div class="col-2 text-end">
                                                                <!-- open description button -->
                                                                <button type="button" class="btn expert-info justify-content-center text-center form-shadow" data-bs-toggle="modal" data-bs-target="#expert-description-modal">
                                                                    <i class="fas fa-info"></i>
                                                                </button>
                                                                <!-- /open description button -->

                                                                <!-- description modal -->
                                                                <div class="modal fade" id="expert-description-modal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="expert-description-modal-title" aria-hidden="true">
                                                                    <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg">
                                                                        <div class="modal-content p-4">
                                                                            <div class="modal-header border-0 p-0 d-flex justify-content-center">
                                                                                <span class="d-lg-block d-none modal-title expert-detail-fields-of-experience-description-header mp-rbk mp-font-weight-semi-bold" id="expert-description-modal-title">
                                                                                    Begriffserklärung
                                                                                </span>
                                                                            </div>
                                                                            <div class="modal-body py-4">
                                                                                <p class="field-of-expertise-description mp-qks font-size-field-of-expertise-description text-start">
                                                                                    Damit sich deine potenziellen Kunden ein Bild von dir machen können, beschreibe dich und deine Fähigkeiten möglichst genau.<br>
                                                                                    Füge Fotos und Kurzvideos hinzu, mit welchen du dich und dein Angebot kurz beschreibst.<br>
                                                                                    Denn Sympathie spielt vor allem im gesundheitlichen Bereich eine große Rolle.<br>
                                                                                    Sie führt automatisch zu mehr Authentizität und bietet eine gute Basis für eine weitere Zusammenarbeit.
                                                                                </p>
                                                                            </div>
                                                                            <div class="modal-footer d-flex flex-column justify-content-center border-0 p-0">
                                                                                <button type="button" class="btn btn-mp-green font-size-btn-close-field-of-expertise-description mp-font-weight-medium py-2 px-5" data-bs-dismiss="modal">Schließen</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- /description modal -->
                                                            </div>

                                                            <div class="col-12 d-flex flex-column" style="padding-top: 5px">
                                                                <textarea class="form-control" id="account-expert-about-me-description" rows="5" maxlength="500" v-model="expertProfile.desc"/>
                                                                <span class="align-self-end mp-rbk font-size-account-form-addon">max. 500 Zeichen</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-5">
                                                        <div class="col-12">
                                                            <span
                                                                class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Expertenprofil</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <span
                                                                    class="mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Ich bin Experte in folgenden Kategorien & Fachgebieten</span>
                                                            </div>
                                                        </div>
                                                        <expert-areas-of-expertise :expert="expertProfile" @change="getExpertProfile" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-lg-6">
                                                <div class="container px-0">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <span
                                                                class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Kontaktdaten</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <template v-if="expertProfile.phone">
                                                                    <label for="account-expert-about-me-phone"
                                                                           class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Telefon</label>
                                                                    <input type="text" class="form-control mb-3"
                                                                           id="account-expert-about-me-phone"
                                                                           v-model="expertProfile.phone">
                                                                </template>

                                                                <template v-else>
                                                                    <label
                                                                        for="account-expert-phone"
                                                                        class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    >Telefon</label>
                                                                    <div class="input-group mb-3">
                                                                        <select class="form-select phone-country-select" id="account-expert-phone-areacode" aria-label="Land" v-model="phonePrefix">
                                                                            <option value="+43" selected>+43</option>
                                                                            <option value="+49">+49</option>
                                                                            <option value="+41">+41</option>
                                                                        </select>
                                                                        <input type="text" class="form-control" id="account-expert-phone" v-model="phone">
                                                                    </div>
                                                                </template>

                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-email"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">E-Mail</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-email"
                                                                       v-model="expertProfile.email">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-homepage"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Website</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-homepage"
                                                                       v-model="expertProfile.web_url">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-facebook"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Facebook</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-facebook"
                                                                       v-model="expertProfile.fb">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-linkedin"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">LinkedIn</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-linkedin"
                                                                       v-model="expertProfile.linkedin">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-youtube"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Youtube</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-youtube"
                                                                       v-model="expertProfile.youtube">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-instagram"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Instagram</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-instagram"
                                                                       v-model="expertProfile.instagram">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-twitter"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">X (Twitter)</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-twitter"
                                                                       v-model="expertProfile.twitter">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-podcast"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Podcast</label>
                                                                <input type="text" class="form-control mb-3"
                                                                       id="account-expert-about-me-podcast"
                                                                       v-model="expertProfile.podcast">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-12">
                                                            <span
                                                                class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Unternehmensdaten</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label
                                                                    for="account-expert-about-me-company"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                >Firmenwortlaut</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    id="account-expert-about-me-company"
                                                                    v-model="expertProfile.company_title"
                                                                >
                                                            </div>
                                                        </div>

                                                        <label for="account-expert-about-me-search-insurances"
                                                               class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular pt-2">
                                                            Kassen (Mehrfachauswahl möglich)
                                                        </label>
                                                        <Select2
                                                            id="account-expert-about-me-search-insurances"
                                                            aria-label="Kassen"
                                                            v-model="insurances"
                                                            :options="optionsInsurances"
                                                            :settings="select2Settings"
                                                        />

                                                        <label for="registration-expert-vatnr" class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular pt-2">UID-NUMMER</label>
                                                        <div class="input-group mb-3">
                                                            <select class="form-select phone-country-select" style="min-width: 85px" id="registration-expert-phone-areacode" aria-label="Land" v-model="expertProfile.company_tax_uid_prefix">
                                                                <option value="ATU" selected>ATU</option>
                                                                <option value="DE">DE</option>
                                                                <option value="CHE">CHE</option>
                                                            </select>
                                                            <input type="text" class="form-control" id="registration-expert-vatnr" v-model="expertProfile.company_tax_uid">
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label
                                                                    for="account-expert-about-me-street"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    :class="$v.expertProfile.company_address.$error ? 'error-msg' : ''"
                                                                >Rechnungsadresse *</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    :class="$v.expertProfile.company_address.$error ? 'error-border' : 'mb-3'"
                                                                    id="account-expert-about-me-street"
                                                                    v-model="$v.expertProfile.company_address.$model"
                                                                >
                                                                <div v-if="$v.expertProfile.company_address.$error" class="error-msg">Bitte Rechnungsadresse angeben</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-country"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                       :class="$v.expertProfile.company_country.$error ? 'error-msg' : ''"
                                                                >Land*</label>
                                                                <select
                                                                    class="form-select"
                                                                    :class="$v.expertProfile.company_country.$error ? 'error-border' : 'mb-3'"
                                                                    id="account-expert-about-me-country"
                                                                    aria-label="Land"
                                                                    v-model="$v.expertProfile.company_country.$model"
                                                                >
                                                                    <option value="AT" selected>Österreich</option>
                                                                    <option value="DE">Deutschland</option>
                                                                    <option value="CH">Schweiz</option>
                                                                </select>
                                                                <div v-if="$v.expertProfile.company_country.$error" class="error-msg">Bitte Land angeben</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-4 col-md-3">
                                                                <label
                                                                    for="account-expert-about-me-zip"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    :class="$v.expertProfile.company_zip.$error ? 'error-msg' : ''"
                                                                >PLZ *</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    :class="$v.expertProfile.company_zip.$error ? 'error-border' : 'mb-3'"
                                                                    id="account-expert-about-me-zip"
                                                                    v-model="$v.expertProfile.company_zip.$model"
                                                                >
                                                                <div v-if="$v.expertProfile.company_zip.$error" class="error-msg">Bitte Postleitzahl angeben</div>
                                                            </div>

                                                            <div class="col-8 col-md-9">
                                                                <label
                                                                    for="account-expert-about-me-city"
                                                                    class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular"
                                                                    :class="$v.expertProfile.company_city.$error ? 'error-msg' : ''"
                                                                >Ort *</label>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    :class="$v.expertProfile.company_city.$error ? 'error-border' : 'mb-3'"
                                                                    id="account-expert-about-me-city"
                                                                    v-model="$v.expertProfile.company_city.$model"
                                                                >
                                                                <div v-if="$v.expertProfile.company_city.$error" class="error-msg">Bitte Ort angeben</div>
                                                            </div>
                                                        </div>

                                                        <div class="row mt-2">
                                                            <div class="col-12" style="font-size: 0.8rem">
                                                                Felder mit * sind Pflichtfelder
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-5">
                                                        <div class="col-12">
                                                            <span
                                                                class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Such-Tags</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label for="account-expert-about-me-search-tags"
                                                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Nach
                                                                    welchen Such-Tags kannst du gefunden werden<br>(Mehrfachauswahl möglich)
                                                                </label>
                                                                <Select2
                                                                    id="account-expert-about-me-search-tags"
                                                                    aria-label="Such-Tags"
                                                                    v-model="tags"
                                                                    :options="optionsTags"
                                                                    :settings="select2Settings"
                                                                />
                                                            </div>
                                                        </div>
                                                        <!--
                                                        <div class="row" style="margin-top: 10px">
                                                            <div class="col-12 d-flex">
                                                                <i class="fas fa-plus-circle me-2 font-size-account-expert-about-me-search-tag-add-icon"></i>

                                                            </div>
                                                        </div>
                                                        -->
                                                    </div>

                                                    <div class="row">
                                                        <div
                                                            class="col-12 col-lg-6 offset-lg-6 d-flex flex-column">
                                                            <button
                                                                type="button"
                                                                class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5"
                                                                @click="doExpertProfileSave"
                                                                :disabled="$v.$invalid"
                                                            >
                                                                Speichern
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /about-me content -->
                                </div>


                                <!-- my-offers content -------------------------------------------------------------------------------------------------------->
                                <div
                                    v-if="subscribedProduct && subscribedProduct.level > 1"
                                    class="tab-pane fade"
                                    :class="activeTab === tabPages.offers ? 'show active' : ''"
                                    id="my-offers"
                                    role="tabpanel"
                                    aria-labelledby="my-offers-tab"
                                >
                                    <template v-if="!showCourseDetail">
                                        <div class="row mb-4">
                                            <div class="col-12 d-flex justify-content-end">
                                                <a class="mp-green mp-qks mp-font-weight-medium font-size-account-expert-about-me-add-course text-decoration-none d-flex" href="#" @click="showDlgCreate = true">
                                                    <i class="fas fa-plus-circle me-2 font-size-account-expert-about-me-add-course-icon"></i>
                                                    Weiteres Angebot hinzufügen
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <CourseCard :edit-mode="true" :course="c" v-for="c in courses" :key="`${keyPrefix}_${c.id}_course`" @edit="doCourseEdit(c.id)" @delete="showDialogCourseDelete(c.id)"/>
                                        </div>
                                    </template>

                                    <!-- add course ------------------------------------------------------------------------------------------------------>
                                    <Course v-else
                                            :id="selectedCourseId"
                                            :expert-profile="expertProfile"
                                            @save="doCourseSave($event)"
                                            @cancel="doCourseEditCancel"
                                            @refresh-courses="getExpertCourses"
                                    />
                                </div>


                                <!-- favorites content ------------------------------------------------------------------------------------------------------------>
                                <div class="tab-pane fade"
                                     :class="activeTab === tabPages.favExperts ? 'show active' : ''" id="favorites"
                                     role="tabpanel" aria-labelledby="favorites-tab">
                                    <div class="container px-0">
                                        <div class="row">
                                            <ExpertCard :id="e.id" :is-favorite="true" v-for="e in favoriteExperts" :key="`${keyPrefix}_${e.id}_fav`"/>
                                        </div>
                                    </div>
                                </div>

                                <!-- favorites courses ------------------------------------------------------------------------------------------------------------>
                                <!--
                                <div class="tab-pane fade"
                                     :class="activeTab === tabPages.favCourses ? 'show active' : ''" id="favorite-courses"
                                     role="tabpanel" aria-labelledby="favorite-courses-tab">
                                    <div class="container px-0">
                                        <div class="row">
                                            <CourseCard smaller :course="e" v-for="e in favoriteCourses" :key="`${keyPrefix}_${e.id}_fav_course`" />
                                        </div>
                                    </div>
                                </div>
                                -->

                                <!-- reviews content ------------------------------------------------------------------------------------------------------------>
                                <div class="tab-pane fade"
                                     :class="activeTab === tabPages.reviews ? 'show active' : ''"
                                     id="reviews"
                                     role="tabpanel"
                                     aria-labelledby="reviews-tab"
                                >
                                    <div class="container px-0">
                                        <div class="row">
                                            <div class="col-12">
                                                <div
                                                    class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input"
                                                               type="checkbox"
                                                               v-model="userProfile.has_email_notification"
                                                               id="account-expert-notify-on-review"
                                                               @change="doUserProfileSave"
                                                        >
                                                        <label
                                                            class="form-check-label mp-qks font-size-account-expert-settings-checkboxes mp-font-weight-medium"
                                                            for="account-expert-notify-on-review">
                                                            Ich möchte per E-Mail benachrichtigt werden, wenn
                                                            Nutzer einen Erfahrungsbericht über mich
                                                            veröffentlicht haben.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <Comment :comment="c" v-for="c in comments" :key="c.id"
                                                 @save="doUpdateComment(c.id, $event)"
                                                 @report="doReportComment(c.id)"
                                                 @delete="doDeleteComment(c.id)"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <!-- subscriptions ------------------------------------------------------------------------------------------------->
                                <div class="tab-pane fade" :class="activeTab === tabPages.subscription ? 'show active' : ''" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
                                    <div class="container px-0">
                                        <div class="container-fluid bg-lightblue px-0 py-0">

                                            <!-- show products -->
                                            <div class="row" v-if="!hasSubscription && !showPayment">
                                                <div class="col-12" v-if="userProfile.documents_verified">
                                                    <div class="container py-0 px-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <span class="mp-rbk mp-font-weight-semi-bold font-size-expert-register-payment-title d-block text-center">Zahlungsplan wählen</span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-10 offset-1 py-1">
                                                                <p class="mp-qks font-size-payment-subtitle mp-font-weight-medium text-center">Wähle aus drei verschiedenen Paketen, den für dich besten Zahlungsplan. </p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3" v-for="p in products" :key="`${keyPrefix}_p_${p.id}`">
                                                                <ProductCard :product="p" @buy="doSubscribe(p.id)"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- not verified yet = no subscription-buy -->
                                                <div class="col-12" v-else>
                                                    <div class="container py-0 px-0">
                                                        <div class="row">
                                                            <div class="col-12 mb-4">
                                                                <span class="mp-rbk font-size-expert-register-payment-title d-block text-center">
                                                                    Ein Abonnement kann erst nach abgeschlossener Verifikation erworben werden.
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3" v-for="p in products" :key="`${keyPrefix}_p_${p.id}`">
                                                                <ProductCard :product="p" @buy="doSubscribe(p.id)" :disable="true"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- show current subscription -->
                                            <div class="row" v-if="hasSubscription && !showPayment">
                                                <div class="col-12">
                                                    <div class="container py-0 px-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <span class="mp-rbk mp-font-weight-semi-bold font-size-expert-register-payment-title d-block text-center">Dein aktuelles Paket</span>
                                                            </div>
                                                        </div>
                                                        <!--
                                                        <div class="row">
                                                            <div class="col-10 offset-1 py-1">
                                                                <p class="mp-qks font-size-payment-subtitle mp-font-weight-medium text-center">Wähle aus drei verschiedenen Paketen, den für dich besten Zahlungsplan. </p>
                                                            </div>
                                                        </div>
                                                        -->
                                                        <div class="row mt-5">
                                                            <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-3" v-for="p in products" :key="`${keyPrefix}_p_${p.id}`">
                                                                    <ProductCard
                                                                        v-if="p.id === subscribedProduct.id"
                                                                        :product="p"
                                                                        :is-subscription="true"
                                                                        :start-date="subscription.start_date"
                                                                        :end-date="subscription.end_date"
                                                                        :canceled="subscription.canceled"
                                                                        :key="`${keyPrefix}_p_${p.id}`"
                                                                        @cancel="doCancelSubscription"
                                                                        @apply-discount="showDlgApplyDiscount = true"
                                                                    />
                                                                    <ProductCard
                                                                        v-else
                                                                        :product="p"
                                                                        :disable="subscription.canceled || p.level <= subscribedProduct.level"
                                                                        :upgrade-button="!subscription.canceled && p.level > subscribedProduct.level"
                                                                        :key="`${keyPrefix}_p_${p.id}`"
                                                                        @upgrade="doSubscribe(p.id, true)"
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- payment: data collection -->
                                            <div class="row" v-if="showPayment">
                                                    <div class="col-12 col-lg-4" v-if="selectedProduct">
                                                        <ProductCard :product="selectedProduct" :hide-button="true" style="max-width: 360px"/>
                                                    </div>

                                                    <div class="col-12 col-lg-8">
                                                        <div class="row mb-4 mb-lg-1 mt-5 mt-lg-0 text-center text-lg-start">
                                                            <h1>Bezahlung</h1>
                                                        </div>

                                                        <div class="row">
                                                            <!-- coupon is valid & will be applied -->
                                                            <ul class="ms-3">
                                                                <li v-if="selectedProduct"><div class="col-12 mt-2" style="font-size: 1.2rem; font-weight: normal;">{{selectedProductInfo}}</div></li>
                                                                <li v-if="coupon"><div class="col-12 mb-4" style="font-size: 1.2rem; font-weight: normal;" v-html="couponInfo"></div></li>
                                                            </ul>

                                                            <template v-if="!showPaymentElement && !paymentInProgress">
                                                                <div class="col-12 col-lg-auto text-start">
                                                                    <a class="btn btn-mp-red-subscribe mp-qks mp-font-weight-medium mt-3 w-100" @click="cancelPayment">Zurück</a>
                                                                </div>
                                                            </template>

                                                            <div class="col-12 col-lg-auto">
                                                                <!-- coupon -->
                                                                <a
                                                                   v-if="!coupon && !showPaymentElement && !paymentInProgress"
                                                                   class="btn btn-mp-orange-subscribe mp-qks mp-font-weight-medium mt-3 w-100"
                                                                   @click="showDlgApplyCoupon = true"
                                                                >
                                                                    Gutschein anwenden
                                                                </a>
                                                            </div>

                                                            <template v-if="!showPaymentElement && !paymentInProgress">
                                                                <div class="col-12 col-lg-auto text-start">
                                                                    <a v-if="paymentMethod.needs_approval" class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium mt-3 w-100"  @click="buildPaymentMethod">Weiter</a>
                                                                    <a v-else class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium mt-3 w-100"  @click="createSubscription">Weiter</a>
                                                                </div>
                                                            </template>
                                                        </div>

                                                        <!-- paymentElement -->
                                                        <template v-if="showPaymentElement">
                                                            <div v-if="!paymentReady" style="font-size: 1.2rem; font-weight: bold; margin-top: 5px;">
                                                                Einen Moment bitte...
                                                            </div>

                                                            <form id="payment-form">
                                                                <div id="payment-element" ref="payment-element">
                                                                    <!-- Elements will create form elements here -->
                                                                </div>
                                                                <div id="error-message">
                                                                    {{ paymentErrorMsg }}
                                                                </div>
                                                            </form>

                                                            <template v-if="paymentReady && !paymentInProgress">
                                                                <div class="row">
                                                                    <div class="col-12 col-lg-auto">
                                                                        <a class="btn btn-mp-red-subscribe mp-qks mp-font-weight-medium mt-3 w-100" @click="cancelPayment">Zurück</a>
                                                                    </div>
                                                                    <div class="col-12 col-lg-auto">
                                                                        <a class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium mt-3 w-100" style="margin-right: 10px" @click="confirmPaymentMethod">Zahlungspflichtig bestellen</a>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </template>

                                                        <!-- payment: status -->
                                                        <div v-if="paymentInProgress" class="mt-3" style="font-size: 1.2rem; font-weight: bold;">
                                                            Transaktion läuft, einen Moment bitte...
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- settings ------------------------------------------------------------------------------------------------->
                                <div class="tab-pane fade" :class="activeTab === tabPages.settings ? 'show active' : ''" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                    <div class="container px-0">
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Einstellungen</span>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div
                                                            class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                            <div class="form-check">
                                                                <input class="form-check-input"
                                                                       type="checkbox"
                                                                       v-model="userProfile.has_newsletter"
                                                                       id="account-expert-subscribe-newsletter"
                                                                       @change="doNewsletterSubscriptionUpdate"
                                                                >
                                                                <label
                                                                    class="form-check-label mp-qks font-size-account-expert-settings-checkboxes mp-font-weight-medium"
                                                                    for="account-expert-subscribe-newsletter">
                                                                    Ich möchte folgende Informationen über
                                                                    Werbeaktionen, wissenswerte Informationen und
                                                                    Neuigkeiten von mergeplate.com. per Mail erhalten.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div
                                                            class="bg-white form-shadow-light form-rounded-left form-rounded-right p-4 mb-4">
                                                            <div class="form-check">
                                                                <input class="form-check-input"
                                                                       type="checkbox"
                                                                       v-model="userProfile.has_email_notification"
                                                                       id="account-expert-notify-on-review"
                                                                       @change="doUserProfileSave"
                                                                >
                                                                <label
                                                                    class="form-check-label mp-qks font-size-account-expert-settings-checkboxes mp-font-weight-medium"
                                                                    for="account-expert-notify-on-review">
                                                                    Ich möchte per E-Mail benachrichtigt werden, wenn
                                                                    Nutzer einen Erfahrungsbericht über mich
                                                                    veröffentlicht haben.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- dialogs -->
        <CourseCreate v-if="showDlgCreate" @create="doCourseCreate" @close="showDlgCreate = false"/>
        <Confirm v-if="showDlgCourseDelete" title="Kurs löschen" :msg="`Kurs '${selectedCourse.title}' wirklich löschen?`" @positive="doCourseDelete" @close="showDlgCourseDelete = false"/>
        <Confirm v-if="showDlgExpertProfileImageDelete" title="Bild löschen" :msg="`Bild wirklich löschen?`" @positive="doExpertProfileImageDelete" @close="showDlgExpertProfileImageDelete = false"/>
        <PasswordChangeReq v-if="showDlgPasswordChange" email="xxx@aaaa.cccc" @success="passwordChangeSuccess" @failed="passwordChangeFailed" @close="showDlgPasswordChange = false" />
        <EmailChangeReq v-if="showDlgEMailChange" @success="emailChangeSuccess" @failed="emailChangeFailed" @close="showDlgEMailChange = false" />
        <ApplyCoupon v-if="showDlgApplyCoupon" :price-id="selectedProduct.stripePriceId" @success="applyCoupon" @close="showDlgApplyCoupon = false" />
        <ApplyDiscount v-if="showDlgApplyDiscount" :price-id="subscribedProduct.stripePriceId" @success="applyDiscount" @close="showDlgApplyDiscount = false" />

        <!-- toasts -->
        <Toast v-if="showToast" :title="toastTitle" :msg="toastMsg" :background-color="toastBgColor" :delay="10000" @close="showToast = false" />

    </div>
    <!-- /content -->
</template>




<script>
    import Configs from '../../configs';
    import {mixinBasics} from "../../mixins/basics";
    import {apiCall, buildFinalUrl} from "../../helpers";
    import {loadStripe} from '@stripe/stripe-js/pure';
    import CourseCard from "../../components/CourseCard";
    import ExpertCard from "../../components/ExpertCard";
    import Course from "../../components/Course";
    import CourseCreate from "../../components/modals/CourseCreate";
    import {mapActions} from "vuex";
    import Toast from "../../components/Toast";
    import Confirm from "../../components/modals/Confirm";
    import FileUpload from "../../components/FileUpload";
    import Select2 from 'v-select2-component';
    import ProductCard from "../../components/ProductCard";
    import Comment from "../../components/Comment";
    import ApplyCoupon from "../../components/modals/ApplyCoupon";
    import EmailChangeReq from "../../components/modals/EmailChangeReq";
    import PasswordChangeReq from "../../components/modals/PasswordChangeReq";
    import Const from "../../constants";
    import ExpertAreasOfExpertise from "../../components/ExpertAreasOfExpertise.vue";
    import {maxLength, required} from "vuelidate/lib/validators";
    import ApplyDiscount from "../../components/modals/ApplyDiscount.vue";

    let stripe = undefined;
    let paymentElements = undefined;

    loadStripe.setLoadParameters({advancedFraudSignals: false});
    (async() => {stripe = await loadStripe(Configs.stripe[Configs.applicationMode].publishable_key);})();

    const api = Configs.restApi.endpoints.map;
    const tabPages = {
        account: 1,
        about: 2,
        offers: 3,
        favExperts: 4,
        reviews: 5,
        subscription: 6,
        settings: 7,
        favCourses: 8
    };



    export default {
        name: "ExpertAccount",

        metaInfo() {
            return {
                title: 'Expertenkonto | mergeplate.com',
                meta: [
                    { name: 'Expertenkonto | mergeplate.com' },
                    { title: 'Expertenkonto | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    'Expertenkonto | mergeplate.com',
                    'Expertenkonto - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit',
                    this.$route.path
                ))
            };
        },

        components: {
            ApplyDiscount,
            ExpertAreasOfExpertise,
            PasswordChangeReq,
            EmailChangeReq,
            ApplyCoupon,
            Comment,
            ProductCard,
            FileUpload,
            Confirm,
            CourseCreate,
            Course,
            ExpertCard,
            CourseCard,
            Toast,
            Select2
        },

        mixins: [
            mixinBasics
        ],

        props: {
            preselectedTab: {
                required: false,
            },

            paymentStatus: {
                type: String,
                required: false,
                default: undefined
            }
        },

        data() {
            return {
                keyPrefix: 'expAcc',
                tabPages,
                activeTab: undefined,
                isLoading: true,
                paymentReady: false,
                paymentInProgress: false,

                toastMsg: '',
                toastTitle: '',
                toastBgColor: undefined,

                showDlgCreate: false,
                showDlgCourseDelete: false,
                showDlgExpertProfileImageDelete: false,
                showDlgPasswordChange: false,
                showDlgEMailChange: false,
                showDlgApplyCoupon: false,
                showDlgApplyDiscount: false,

                showCourseDetail: false,
                showToast: false,
                showPayment: false,
                showPaymentElement: false,

                userProfile: undefined,
                expertProfile: {},
                categories: undefined,
                areas: undefined,
                tags: undefined,
                insurances: undefined,
                courses: undefined,
                reviews: undefined,

                // phone-"special"
                phone: undefined,
                phonePrefix: undefined,

                selectedCourseId: undefined,
                selectedExpertProfileImageId: undefined,
                selectedProduct: undefined,
                coupon: undefined,
                isUpgrade: undefined,

                // stripe-related
                paymentMethod: {},
                paymentOptions: {},
                paymentErrorMsg: '',

                academTitles: Const.academicTitles
            };
        },

        validations() {
            return {
                expertProfile: {
                    salutation: { required },
                    firstname: { required },
                    lastname: { required },
                    // company_title: { required },
                    company_address: { required },
                    company_zip: { required },
                    company_city: { required },
                    company_country: { required },
                    desc_brief: {
                        maxLength: maxLength(80)
                    }
                },
            };
        },

        computed: {
            images() {
                let img = [];
                for (let i = 0; i <= 5; i++) {
                    let m = this.expertProfile.media[i];
                    img.push(m ? m : undefined)
                }
                return img;
            },

            selectedCourse() {
                return this.courses.find(c => c.id === this.selectedCourseId);
            },

            expertMediaUploadUrl() {
                return api.expert.media.create.url;
            },

            optionsTags()
            {
                return this._tags.map(e => { return {text: e.title, id: e.id}; });
            },

            optionsInsurances()
            {
                return this._insurances.map(e => { return {text: e.title, id: e.id}; });
            },

            select2Settings()
            {
                return {
                    multiple: true,
                    dropdownAutoWidth: false,
                    width: '100%',
                };
            },

            products() {
                return this._products.map(p => {
                    let meta = p.stripe_product_detail.metadata;
                    return {
                        id: p.id,
                        title: p.title,
                        colorClass: meta.color_class,
                        icon: meta.icon,
                        price: p.price,
                        level: p.level,
                        paymentPlan: meta.text_desc_plan,
                        //paymentDiscount: meta.text_desc_discount.length > 3 ? meta.text_desc_discount : '',
                        paymentInfosHeader: meta.text_desc_info_header.length > 3 ? meta.text_desc_info_header : '',
                        paymentInfos: meta.text_desc_info,
                        label: meta.text_title,
                        labelPaymentPlan: meta.text_sub_title,
                        stripePriceId: p.stripe_price_id
                    };
                });
            },

            hasSubscription() {
                return !!this.profile.subscription;
            },

            subscribedProduct() {
                if (this.hasSubscription) {
                    let stripePriceId = this.profile.subscription.stripe_subscription_detail.items.data[0].price.id;
                    return this.products.find(p => p.stripePriceId === stripePriceId);
                }
                return undefined;
            },

            subscription() {
                return this.profile.subscription;
            },

            comments() {
                return this.expertProfile.reviews;
            },

            expertNameMeta() {
                return `${this.expertProfile.firstname}-${this.expertProfile.lastname}`;
            },

            /*
            courses() {
                return this._courses.filter(c => c.user_id === this.profile.id);
            }
            */

            couponInfo() {
                let durationMonth = this.coupon.duration > 1 ? 'Monate' : 'Monat';
                return `Gutschein '<b>${ this.coupon.title }</b>' ${ this.coupon.duration ? `(Laufzeit: ${ this.coupon.duration } ${durationMonth})` : '' } wird angewendet.`;
            },

            selectedProductInfo() {
                return `${this.selectedProduct.title}  € ${this.selectedProduct.price} / ${this.selectedProduct.paymentPlan} (zzgl. 20% USt.)`;
            }
        },

        methods: {
            ...mapActions('main', {
                loadUserProfile: 'loadUserProfile',
                _loadCourses: 'loadCourses'
            }),

            async getData(req) {
                let res = await apiCall(req.url, req.method);
                return res.data;
            },

            async getReviews() {
                let req = api.comment.index;
                let res = await apiCall(buildFinalUrl(req.url, {'expertUserId': this.profile.id}), req.method);
                this.expertProfile.reviews = res.data;
            },

            async getExpertProfile() {
                this.expertProfile = await this.getData(api.expert.show);
                this.areas = this.expertProfile.areas.map(e => e.id);
                this.categories = this.expertProfile.categories.map(e => e.id);
                this.tags = this.expertProfile.tags.map(e => e.id);
                this.insurances = this.expertProfile.insurances.map(e => e.id);
            },

            async getExpertCourses() {
                this.courses = undefined;
                this.courses = await this.getData(api.expert.course.index);
            },

            async getExpertSubscription() {
                this.subscription = await this.getData(api.subscription.index);
            },

            // ----------------------------------------------- Course
            async doCourseCreate(e) {
                let payload = {course_type_id: e.typeId, title: e.title};
                let req = api.course.create;
                let res = await apiCall(req.url, req.method, payload);
                await this.doReloadCourses();
                this.showDlgCreate = false;
                this.selectedCourseId = res.data.id;
                this.showCourseDetail = true;
            },

            doCourseEdit(courseId) {
                console.log('doCourseEdit: ' + courseId);
                this.selectedCourseId = courseId;
                this.showCourseDetail = true;
            },

            async doCourseSave(data) {
                let req = api.course.update;
                await apiCall(buildFinalUrl(req.url, {id: data.id}), req.method, data, false);
                await this.doReloadCourses();
                this.showCourseDetail = false;
                this.selectedCourseId = undefined;
                this.showInfoToast('Kurs aktualisieren', 'Kurs wurde erfolgreich aktualisiert.');
            },

            async doReloadCourses() {
                await this.getExpertCourses();
                await this._loadCourses();
            },

            doCourseEditCancel() {
                this.showCourseDetail = false;
                this.selectedCourseId = undefined;
            },

            showDialogCourseDelete(courseId) {
                this.selectedCourseId = courseId;
                this.showDlgCourseDelete = true;
            },

            async doCourseDelete() {
                let courseId = this.selectedCourseId;
                let req = api.course.delete;
                await apiCall(buildFinalUrl(req.url, {id: courseId}), req.method);
                this.showInfoToast('Kurs löschen', 'Kurs wurde erfolgreich gelöscht.');
                this.courses = await this.getData(api.expert.course.index);
            },

            // ----------------------------------------------- User-/Expert-Profile
            async doUserProfileSave() {
                let up = this.userProfile;
                let payload = {
                    salutation: up.salutation,
                    firstname: up.firstname,
                    lastname: up.lastname,
                    title: up.title,
                    has_email_notification: up.has_email_notification
                };
                let req = api.user.update;
                await apiCall(req.url, req.method, payload, false);
                // reload user-profile
                this.showInfoToast('Benutzerinformationen', 'Benutzerinformationen wurde erfolgreich aktualisiert.');
                await this.loadUserProfile(true);
            },

            async doNewsletterSubscriptionUpdate() {
                let req = this.userProfile.has_newsletter ? api.user.newsletter.subscribe : api.user.newsletter.unsubscribe;
                await apiCall(req.url, req.method, {has_newsletter: this.userProfile.has_newsletter}, false);
                // reload user-profile
                this.showInfoToast('Benutzerinformationen', 'Benutzerinformationen wurde erfolgreich aktualisiert.');
                await this.loadUserProfile(true);
            },

            async doExpertProfileSave() {
                let payload = Object.assign({},
                    this.expertProfile,
                    {tags: this.tags, categories: this.categories, areas: this.areas, insurances: this.insurances},
                    !this.expertProfile.phone ? {phone: this.phonePrefix && this.phone ? `${this.phonePrefix} ${this.phone}` : ''} : {}
                );
                let req = api.expert.update;
                await apiCall(req.url, req.method, payload, true);
                this.showInfoToast('Expertenprofil', 'Profil wurde erfolgreich aktualisiert.');
                await this.getExpertProfile();
                await this._reloadExperts();
            },

            setArea(id, val) {
                if (val) {
                    this.areas.push(id);
                } else {
                    this.areas = this.areas.filter(e => e !== id);
                }
            },

            setCategory(id, val) {
                if (val) {
                    this.categories.push(id);
                } else {
                    this.categories = this.categories.filter(e => e !== id);
                }
            },

            async doExpertProfileImageDelete() {
                let imgId = this.selectedExpertProfileImageId;
                let req = api.expert.media.delete;
                await apiCall(buildFinalUrl(req.url, {id: imgId}), req.method);
                // reload user-profile
                await this.getExpertProfile();
            },

            passwordChangeSuccess() {
                this.showInfoToast('Passwort ändern', 'Passwort wurde erfolgreich aktualisiert.');
            },

            passwordChangeFailed() {
                this.showInfoToast('Passwort ändern', 'Passwortänderungen fehlgeschlagen. Bitte überprüfen sie ihre Eingabe.', '#842029');
            },

            emailChangeSuccess() {
                this.showInfoToast('EMail ändern', 'EMail-Adresse wurde erfolgreich aktualisiert.');
            },

            emailChangeFailed() {
                this.showInfoToast('Passwort ändern', 'Änderungen der EMail-Adresse fehlgeschlagen. Bitte überprüfen sie ihre Eingabe.', '#842029');
            },

            showDialogExportProfileImageDelete(imgId) {
                this.selectedExpertProfileImageId = imgId;
                this.showDlgExpertProfileImageDelete = true;
            },

            hasCategory(cId) {
                return this.categories.find(c => c === cId);
            },

            hasArea(aId) {
                return this.areas.find(a => a === aId);
            },

            showInfoToast(title, msg, backBgColor = undefined) {
                this.toastTitle = title;
                this.toastMsg = msg;
                this.toastBgColor = backBgColor;
                this.showToast = true;
            },

            async doSubscribe(productId, isUpgrade = false)
            {
                this.paymentMethod = await this.getPaymentMethod();
                this.selectedProduct = this.products.find(p => p.id === productId);
                this.coupon = undefined;
                this.showPayment = true;
                this.isUpgrade = isUpgrade;
            },

            applyCoupon(coupon) {
                this.coupon = coupon;
            },

            async applyDiscount(coupon) {
                console.log('applyDiscount');
                let req = api.subscription.applyDiscount;
                await apiCall(buildFinalUrl(req.url, {'couponCode': coupon.code}), req.method);
                await this.loadUserProfile(true);
            },

            async createSubscription() {
                console.log('createSubscription');
                this.paymentInProgress = true;
                let req = api.subscription.create;
                let payload = Object.assign({
                        'price_id': this.selectedProduct.stripePriceId,
                        'payment_method_id': this.paymentMethod.payment_method_id
                    },
                    this.coupon ? {'coupon_code': this.coupon.code} : {},
                    this.isUpgrade ? {'is_upgrade': 1} : {}
                );

                await apiCall(req.url, req.method, payload);

                setTimeout(async () => {
                    await this.loadUserProfile(true);
                    this.paymentReady = false;
                    this.paymentInProgress = false;
                    this.showPaymentElement = false;
                    this.showPayment = false;
                    this.showInfoToast('Abo', 'Abo erfolgreich abgeschlossen!');
                }, 6000);
            },

            async getPaymentMethod() {
                let req = api.subscription.paymentMethod;
                let res = await apiCall(req.url, req.method);
                return res.data;
            },

            async buildPaymentMethod() {
                console.log('buildPaymentMethod');
                this.showPaymentElement = true;
                // let paymentIntent = await this.createPaymentIntent(this.selectedProduct.stripePriceId)
                // paymentElements = stripe.elements(paymentIntent);
                paymentElements = await stripe.elements({'clientSecret': this.paymentMethod.client_secret});
                // create & mount paymentControl
                const paymentElem = await paymentElements.create('payment').on('ready', () => { console.log('>> PaymentElem rdy.'); this.paymentReady = true; });
                paymentElem.mount(this.$refs["payment-element"]);
            },

            async confirmPaymentMethod() {
                this.paymentInProgress = true;
                let res = await stripe.confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements: paymentElements,
                    confirmParams: {
                        //return_url: `${window.location.href}/success`,
                    },
                    redirect: 'if_required'
                });
                if (res.error) {
                    this.paymentInProgress = false;
                    this.errorMsg = res.error.message;
                    this.showInfoToast('Abo', 'Abokauf abgebrochen!', '#842029');
                } else {
                    // paymentMethod approved -> create subscription
                    this.paymentMethod.payment_method_id = res.setupIntent.payment_method;
                    await this.createSubscription();
                }
            },

            cancelPayment() {
                this.showPaymentElement = false;
                this.showPayment = false;
                this.paymentReady = false;
                this.selectedProduct = undefined;
                this.paymentOptions = undefined;
            },

            async doCancelSubscription() {
                let req = api.subscription.cancel;
                await apiCall(buildFinalUrl(req.url, {'id': this.subscription.stripe_id}), req.method);
                this.showInfoToast('Abo beenden', 'Dein Abo wird beendet...');
                setTimeout(async () => {
                        await this.loadUserProfile(true);
                        this.showInfoToast('Abo beendet', 'Dein Abo wurde erfolgreich beendet.');
                    }, 5000);
            },

            async doUpdateComment(commentId, reply) {
                console.log('doUpdateComment:'); console.log(reply);
                let req = api.comment.update;
                await apiCall(buildFinalUrl(req.url, {'id': commentId}), req.method, {reply: reply});
                await this.getReviews();
            },

            async doReportComment(commentId) {
                console.log('doReportComment');
                let req = api.comment.update;
                await apiCall(buildFinalUrl(req.url, {'id': commentId}), req.method, {report: 1, publish: 0});
                await this.getReviews();
            },

            async doDeleteComment(commentId) {
                console.log('doDeleteComment');
                let req = api.comment.delete;
                await apiCall(buildFinalUrl(req.url, {'id': commentId}), req.method);
                await this.getReviews();
            },

            doExpertPreview() {
                this.$refs.expertPreview.click();
            }
        },

        async created() {
            this.activeTab = this.preselectedTab ? parseInt(this.preselectedTab) : tabPages.about;
            this.userProfile = JSON.parse(JSON.stringify(this.profile)); // create independent deep-copy
            await this.getExpertProfile();
            await this.getExpertCourses();
            this.isLoading = false;
        },

        mounted() {
            window.scrollTo(0, 0);
        }

    }
</script>



<style scoped>
    .overlay-img-actions {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: transparent;
        height: 42px;
        font-size: 1.8rem;
    }

    .uploaded-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .img-delete {
        position: absolute;
        bottom: 0.35rem;
        right: 1.35rem;
        color: #FC3C53;
        background-color: #fff;
        padding: 0.45rem;
        border-radius: 1rem;
    }

    .expert-info {
        /* position: absolute; */
        top: 0.55rem;
        right: 0.55rem;
        width: 2.0rem;
        height: 2.0rem;
        background-color: #7FAA2D;
        color: #fff;
        border-radius: 1rem;
        font-size: 0.8rem;
    }

    .expert-info:hover {
        color: #fff;
    }

    .error-msg {
        color: red;
        margin-top: 0px;
    }

    .error-border {
        border: solid 1px red;
    }
</style>
