<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg">
            <div class="modal-content p-4">
                <!-- header -->
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>

                <!-- body -->
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold" style="font-size: 1.4rem">Qualifikation(en) hochladen</span>
                    <div class="container px-0 mt-4">
                        <!-- upload-related things -->
                        <div class="row mb-4">
                            <div class="col-12 bg-white p-2" v-if="!uploadDone">
                                <p style="font-weight: bold; border: 2px solid #f00; color: #dc3545; padding: 4px; text-align: center;" v-if="!profile.subscription">
                                    Achtung! Du hast noch kein Paket gewählt, somit ist dein Profil noch nicht veröffentlicht.
                                </p>

                                <p class="d-block mp-qks mp-font-weight-medium" style="font-size: 1rem">
                                    Zur Verifizierung deiner Qualifikationen lade bitte Zertifikate, Zeugnisse oder andere Nachweise hoch (max. 5 Dateien als .gif, .jpg, .png, .jpeg oder .pdf).<br>
                                    Solltest du dich noch in einem Fachbereich in Ausbildung befinden, lade auch dafür bitte eine Bestätigung hoch.<br>
                                    Wenn dein Nachweis für mehrere Fachbereiche gültig ist, genügt es, diesen einmal hochzuladen und ohne Dokumente fortzufahren.
                                    <!--
                                    Zur Verifizierung deiner Qualifikation im Bereich <b>{{ category.title }}/{{ area.title }}</b>, lade bitte Zertifikate, Zeugnisse etc. hoch.<br>
                                    Solltest du dich noch in Ausbildung befinden, lade auch dafür bitte eine Bestätigung hoch.<br>
                                    (max. 5 Dateien (mögliche Dateitypen sind {{ allowedFileTypes }}))
                                    -->
                                </p>
                                <ul class="register-expert-file-upload-list ps-0 mt-2 mb-5">
                                    <li class="py-2">
                                        <div class="row mb-3">
                                            <div class="col-md-6 col-sm-12">
                                                <input ref="docUpload" class="d-none" type="file" :accept="allowedFileTypes" id="formFileMultiple" @change="addFile">
                                                <button
                                                    v-if="!uploadInProgress"
                                                    type="button"
                                                    class="btn btn-mp-green font-size-registration-expert-btn-submit-registration mp-font-weight-medium py-2 px-5"
                                                    @click="$refs.docUpload.click()"
                                                    :disabled="fileList.length >= maxFilesCnt || continueWithoutDocuments"
                                                >
                                                    <span v-if="fileList.length >= maxFilesCnt">max. 5 Dateien möglich</span>
                                                    <span v-if="fileList.length < 1">Datei hinzufügen</span>
                                                    <span v-if="fileList.length > 0 && fileList.length < maxFilesCnt">weitere Datei hinzufügen</span>
                                                </button>
                                            </div>
                                            <div class="col-md-6 col-sm-12 pt-2">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="registration-expert-accept-newsletter"
                                                    v-model="continueWithoutDocuments"
                                                    style="margin-right: 10px;"
                                                    @change="clearFilelist"
                                                >
                                                <label class="form-check-label mp-qks font-size-registration-expert-checkboxes mp-font-weight-medium" for="registration-expert-accept-newsletter">
                                                    Ohne Dokumente fortfahren
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="py-2" v-for="f in fileList" :key="`${keyPrefix}_fu_${f.name}`">
                                        <div class="row">
                                            <div class="col-11">
                                                <span class="font-size-registration-expert-file-upload-list-item mp-qks mp-font-weight-semi-bold"><i :class="f.iconClass"></i>{{ f.name }}</span>
                                            </div>
                                            <div class="col-1">
                                                <i class="fas fa-trash-alt text-dark" style="font-size: 1.6rem; cursor: pointer" @click="removeFile(f)"></i>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <!-- upload done -->
                            <div class="col-12 bg-white p-2" v-else>
                                <!-- docs uploaded -->
                                <p v-if="!continueWithoutDocuments" class="d-block mp-qks mp-font-weight-medium" style="font-size: 1rem">
                                    Deine {{ fileList.length > 1 ?
                                        'Qualifikationen wurden erfolgreich hochgeladen, und werden von uns umgehend überprüft.' :
                                        'Qualifikation wurde erfolgreich hochgeladen, und wird von uns umgehend überprüft.' }}<br>
                                </p>
                                <!-- without doc.-upload -->
                                <p v-else class="d-block mp-qks mp-font-weight-medium" style="font-size: 1rem">
                                    Deine Qualifikation wurde erfolgreich hinzugefügt, und wird von uns umgehend überprüft.<br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- footer --->
                <div class="modal-footer border-0 p-0 mt-5">
                    <div class="container px-0" v-if="!uploadDone">
                        <div class="row" v-if="!uploadInProgress">
                            <div class="col-6">
                                <button v-if="!continueWithoutDocuments" type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="fileList.length < 1" @click="doUpload">
                                    Hochladen
                                </button>
                                <button v-else type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="doUpload">
                                    Übernehmen
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100 bg-danger" @click="doCancel">
                                    Abbrechen
                                </button>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div v-if="!continueWithoutDocuments" class="col-12 text-center" style="font-size: 1rem; font-weight: bold;">
                                {{ fileList.length > 1 ? 'Dateien werden' : 'Datei wird' }} hochgeladen... einen Moment bitte.
                            </div>
                        </div>
                    </div>
                    <div class="container px-0" v-else>
                        <div class="row">
                            <div class="col-6 offset-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>



<script>
    import BaseModal from "./BaseModal";
    import { mixinBasics } from "../../mixins/basics";
    import {isBoolean} from "lodash";
    import {apiCall, buildFinalUrl} from "../../helpers";
    import Configs from "../../configs";

    const api = Configs.restApi.endpoints.map;

    const allowedFileTypes = '.gif, .jpg, .png, .jpeg, .pdf'
    const fileType = {
        doc: 'fas fa-file-pdf register-expert-file-upload-icon-pdf me-2',
        img: 'fas fa-file-image register-expert-file-upload-icon-image me-2',
        unknown: 'fas fa-file register-expert-file-upload-icon-image me-2'
    };
    const fileExt = [
        {type: 'application/pdf', iconClass: fileType.doc},
        {type: 'application/doc', iconClass: fileType.doc},
        {type: 'application/docx', iconClass: fileType.doc},
        {type: 'image/png', iconClass: fileType.img},
        {type: 'image/jpg', iconClass: fileType.img},
        {type: 'image/jpeg', iconClass: fileType.img},
        {type: 'image/gif', iconClass: fileType.img},
    ];
    const maxFilesCnt = 5;
    const keyPrefix = 'eaoe-doc-upload';



    export default {
        name: "ExpertAreasOfExpertiseDocumentUpload",

        extends: BaseModal,

        mixins: [
            mixinBasics
        ],

        props: {
            area: {
                type: Object,
                required: true
            },
            category: {
                type: Object,
                required: true
            },
        },


        data() {
            return {
                modalId: 'eaoe-doc-upload-modal',
                modal: undefined,
                files: [],
                uploadInProgress: false,
                uploadDone: false,
                continueWithoutDocuments: false
            }
        },

        computed: {
            allowedFileTypes() {
                return allowedFileTypes;
            },

            fileList() {
                return this.files.map(f => {
                    let ic = fileExt.find(ext => ext.type === f.type);
                    return { name: f.name, iconClass: ic ? ic.iconClass : fileType.unknown };
                });
            },

            maxFilesCnt() {
                return maxFilesCnt;
            },

            keyPrefix() {
                return keyPrefix;
            },
        },

        methods: {
            async doUpload() {
                this.uploadInProgress = true;
                let req = api.expert.expertise.create;
                await apiCall(buildFinalUrl(req.url, {category_id: this.category.id, area_id: this.area.id}), req.method, this.buildMultipartPayload());
                this.uploadDone = true;
                this.uploadInProgress = false;
                this.$emit('change');
            },

            doCancel() {
                this.$emit('cancel');
                this.$emit('close');
            },

            addFile() {
                let file = this.$refs.docUpload.files[0];
                this.files.push(file);
            },

            removeFile(e) {
                this.files.splice(this.files.findIndex(f => f.name === e.name), 1);
            },

            clearFilelist() {
                if (this.continueWithoutDocuments) {
                    this.files = [];
                }
            },

            buildMultipartPayload() {
                let payload = new FormData();
                let files = this.files;
                let data = {};
                // data
                Object.keys(data).forEach((k) => {
                    let d = data[k];
                    // set booleans to 0 or 1 (otherwise they'll get transmitted as strings, like 'true' or 'false', which wont be recognized as 'boolean' by Laravel's validation)
                    d = isBoolean(d) ? (d ? 1 : 0) : d;
                    // omit empty values
                    if (d !== undefined) {
                        payload.append(k, d);
                    }
                });
                // files
                for (let i=0; i <= files.length-1; i++) {

                    payload.append(`file_${i}`, files[i]);
                }
                return payload;
            },
        }
    }
</script>



<style scoped>

</style>
