<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Gutschein anwenden</span>

                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 mb-2">
                                Gutschein wird bei deiner nächsten Abrechung berücksichtigt.
                            </div>
                            <div class="col-12 mb-2">
                                <label for="account-expert-about-me-street"
                                       class="form-label mp-rbk font-size-registration-expert-form-item-label mp-font-weight-regular">Gutschein-Code
                                    *</label>
                                <input
                                    type="text" class="form-control mb-3"
                                    id="account-expert-about-me-street"
                                    v-model="$v.coupon.$model"
                                >
                                <div v-html="errorMsg" v-if="errorMsg" style="color: red; margin-bottom: 5px" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-red-subscribe font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="doCancel">
                                    <span class="spinner-grow-sm text-white" role="status">Abbrechen</span>
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                        :disabled="$v.$invalid || isLoading"
                                        @click="doVerifyCoupon"
                                >
                                    <span class="spinner-grow-sm text-white" role="status">Anwenden</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import BaseModal from "./BaseModal";
    import { required } from "vuelidate/lib/validators";
    import { mixinBasics } from "../../mixins/basics";
    import {apiCall, buildFinalUrl} from "../../helpers";
    import Configs from '../../configs';

    const api = Configs.restApi.endpoints.map;

    export default {
        name: "ApplyDiscount",

        extends: BaseModal,

        mixins: [
            mixinBasics
        ],

        props: {
            priceId: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                keyPrefix: 'applyDiscount',
                modalId: 'apply-discount-modal',
                modal: undefined,
                coupon: undefined,
                errorMsg: undefined,
                isLoading: false
            }
        },

        validations: {
            coupon: { required },
        },

        methods: {
            async doVerifyCoupon() {
                this.isLoading = true;
                let req = api.subscription.coupon.verify;
                let res = await apiCall(buildFinalUrl(req.url, {couponCode: this.coupon}), req.method, {product_price_id: this.priceId});
                if (!res.data.valid) {
                    this.errorMsg = `Gutschein <b>'${this.coupon}'</b> ist ungültig, oder wurde bereits eingelöst.`;
                    this.isLoading = false;
                    return;
                }
                this.$emit('success', res.data.coupon);
                this.$emit('close');
            },

            doCancel() {
                this.$emit('close');
            }
        },
    }
</script>



<style scoped>

</style>
