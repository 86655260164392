<template>

    <div class="row mt-3">
        <div class="col-12 col-md-6 pt-2" v-for="c in _categories" :key="`${keyPrefix}_${c.id}_c`">
            <!-- category -->
            <div class="form-check d-flex py-1">
                <span class="align-self-center form-check-label account-about-me-category-label mp-qks font-size-account-about-me-category mp-font-weight-semi-bold">{{ c.title }}</span>
            </div>
            <!-- area -->
            <div class="form-check d-flex py-1" v-for="a in c.areas" :key="`${keyPrefix}_${a.id}_a`">
                <template v-if="hasArea(a.id)">
                    <!-- verified -->
                    <template v-if="hasArea(a.id).verified">
                        <i
                           class="fas fa-check expert-qualification-remove" style="margin-right: 10px; padding-top: 5px; cursor: pointer;" title="Aktiven Fachbereich entfernen"
                           @click="showDialogAreaOfExpertiseDelete(a)"
                        ></i>
                        <span
                            class="align-self-center form-check-label account-about-me-field-label mp-qks font-size-account-about-me-field mp-font-weight-medium expert-qualification-remove"
                            title="Aktiven Fachbereich entfernen"
                            style="cursor: pointer"
                            @click="showDialogAreaOfExpertiseDelete(c, a)"
                        >
                            {{ a.title }}
                        </span>
                    </template>
                    <!-- NOT verified -->
                    <template v-else>
                        <i class="fas fa-pause-circle expert-qualification-in-review" style="margin-right: 10px;" title="Unterlagen in Überprüfung..."></i>
                        <span
                            class="align-self-center form-check-label account-about-me-field-label mp-qks font-size-account-about-me-field mp-font-weight-medium expert-qualification-in-review-text"
                            title="Unterlagen in Überprüfung..."
                        >
                            {{ a.title }}
                        </span>
                    </template>
                </template>
                <!-- new area -> qualification upload -->
                <template v-else>
                    <i class="fas fa-file-upload expert-file-upload-icon-pdf" style="margin-right: 12px; cursor:pointer;" title="Qualifikation hochladen" @click="showDialogDocUpload(c, a)"></i>
                    <span
                        class="align-self-center form-check-label account-about-me-field-label mp-qks font-size-account-about-me-field mp-font-weight-medium"
                        style="cursor:pointer;"
                        @click="showDialogDocUpload(c, a)"
                        title="Qualifikation hochladen"
                    >
                            {{ a.title }}
                    </span>
                </template>
            </div>
        </div>

        <!-- modals -->
        <expert-areas-of-expertise-document-upload
            v-if="showDlgDocUpload"
            :category="selectedCategory"
            :area="selectedArea"
            @close="showDlgDocUpload = false"
            @change="$emit('change')"
        />

        <confirm
            v-if="showDlgAreaOfExpertiseDelete"
            title="Fachbereich entfernen"
            :msg="`Den aktiven Fachbereich <b>${selectedCategory.title}/${selectedArea.title}</b> wirklich löschen?`"
            @positive="doAreaDelete"
            @negative="showDlgAreaOfExpertiseDelete = false"
            @close="showDlgAreaOfExpertiseDelete = false"
        />
    </div>

</template>



<script>
    import {mixinBasics} from "../mixins/basics";
    import ExpertAreasOfExpertiseDocumentUpload from "./modals/ExpertAreasOfExpertiseDocumentUpload.vue";
    import Confirm from "./modals/Confirm.vue";
    import {apiCall, buildFinalUrl} from "../helpers";
    import Configs from "../configs";

    const api = Configs.restApi.endpoints.map;


    export default {
        name: "ExpertAreasOfExpertise",

        components: {
            Confirm,
            ExpertAreasOfExpertiseDocumentUpload
        },

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'aoe',
                showDlgDocUpload: false,
                showDlgAreaOfExpertiseDelete: false,
                selectedCategory: undefined,
                selectedArea: undefined
            };
        },

        props: {
            expert: {
                type: Object,
                required: true
            }
        },

        methods: {
            hasCategory(cId) {
                return this.expert.categories.find(c => c.id === cId);
            },

            hasArea(aId) {
                return this.expert.areas.find(a => a.id === aId);
            },

            showDialogDocUpload(category, area) {
                this.selectedCategory = category;
                this.selectedArea = area;
                this.showDlgDocUpload = true;
            },

            showDialogAreaOfExpertiseDelete(category, area) {
                this.selectedCategory = category;
                this.selectedArea = area;
                this.showDlgAreaOfExpertiseDelete = true;
            },

            async doAreaDelete() {
                let req = api.expert.expertise.delete;
                await apiCall(buildFinalUrl(req.url, {area_id: this.selectedArea.id}), req.method);
                this.$emit('change');
            }
        },

        created() {
        }
    }
</script>



<style scoped>
    .expert-file-upload-icon-pdf {
        color: rgba(106, 106, 106, 0.49);
        font-size: 1.4rem;
    }
    .expert-qualification-in-review {
        color: #082081FC;
        font-size: 1.2rem;
    }
    .expert-qualification-in-review-text {
        color: #082081FC;
    }
    .expert-qualification-remove {
        color: #7FAA2D
    }
</style>